import { Injectable } from '@angular/core';

import { EnvironmentInterface } from 'src/definitions/environment.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  /**
   * Domain
   */
  private domain: URL;

  /**
   * Constructor
   * @param {any} environment Environment config
   */
  private environment: EnvironmentInterface;

  constructor() {
    this.environment = environment;
    this.domain = new URL(this.environment.apiPath, `${this.environment.apiProtocol}//${this.environment.apiDomain}`);
    if (this.environment.apiPort) {
      this.domain.port = this.environment.apiPort.toString(10);
    }
  }

  public getApiUrl(): string {
    return this.domain.toString();
  }
}
