import { EnvironmentInterface } from '../definitions/environment.interface';

export const environment: EnvironmentInterface = {
  name: 'dev',
  production: true,
  apiPath: 'api',
  apiDomain: 'dev.rent-a-bus.nucleoo.com',
  apiProtocol: 'https:',

  // Sentry settings
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  showDialog: true,
  // Google Tag Manager container ID
  gtmId: '',

  // reCAPTCHA key
  recaptchaKey: '6Lef8xIqAAAAAMynaWp-aBza-A9pICJH1QEH6MwH'
};
